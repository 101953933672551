.card {
    isolation: isolate;
  }
  
  .media-object {
    --border-width: 1px;
    --radius: 24px;
  
    position: relative;
    border-radius: var(--radius);
    border: var(--border-width) solid transparent;
  }
  
  .media-object::before {
    content: " ";
    position: absolute;
    inset: calc(var(--border-width) * -1);
    z-index: -1;
    border: inherit;
    border-radius: inherit;
    background-image: conic-gradient(from var(--angle), #381D6A 80%, #E0D1FF 88%, #E0D1FF 92%, #381D6A 100%);
    background-origin: border-box;
    -webkit-mask:
      linear-gradient(black, black) content-box,
      linear-gradient(black, black);
    mask: linear-gradient(black, black),
      linear-gradient(black, black);
    -webkit-mask-clip: content-box, border-box;
    mask-clip: content-box, border-box;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    animation: spin 3s linear infinite;
  }
  
  @supports not (background: paint(something)) {
    .media-object::before {
      background-image: conic-gradient(#381D6A 80%, #E0D1FF 88%, #E0D1FF 92%, #381D6A 100%);
    }
  }
  
  .media-object:hover::before {
    animation-play-state: paused;
  }
  
  @property --angle {
    syntax: "<angle>";
    inherits: true;
    initial-value: 0turn;
  }
  
  @keyframes spin {
    to {
      --angle: 1turn;
    }
  }
  
  
  
  
  
  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  
  @media (prefers-reduced-motion) {
    html {
      scroll-behavior: none;
    }
  }
  
  html *,
  html *::after,
  html *::before {
    box-sizing: inherit;
  }
  
  body {
    margin: 0;
    min-height: 100vh;
    display: grid;
    place-items: center;
    background-size: cover;
    background-position: center;
    background-image: url("https://i.imgur.com/YvUPGUK.jpg");
  }
  
  .card {
    --text-color: #fff;
    --text-color-2: #F3F8FA;
    --bg-color: #0F0620;
    --border-color: #381D6A;
  
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 644px;
    padding: 32px;
    border-radius: 24px;
    color: var(--text-color);
    background: var(--bg-color);
    border: 1px solid var(--border-color);
    font: 100%/1.6 'Open Sans', sans-serif;
  }
  
  .card__title {
    margin-block: 0;
    font-family: 'Sora', sans-serif;
    font-size: 33px;
    font-weight: 700;
    line-height: 44px;
  }
  
  mark {
    background: linear-gradient(270deg, #D42F4A 0%, #F82BBF 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: #0000;
    -webkit-box-decoration-break: clone;
  }
  
  .card__description {
    color: var(--text-color-2);
    font-size: 21px;
    line-height: 24px;
    margin-block: 0;
  }
  
  .media-object {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    gap: 24px;
  }
  
  .email-input {
    border-radius: 29px;
    width: 450px;
    height: 50px;
  }
  
  .email-input::placeholder{
    padding-left: 20px;
    font-size: 16px;
  }
  
  .email-input:focus {
    padding-left: 25px;
    font-size: 16px;
  }
  
  .email-input:focus::placeholder {
    color: transparent;
  }
  
  .media-object__button {
    border: none;
    margin: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    position: absolute;
    right: 79px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 13px 18px 13px;
    border-radius: 28px;
    background: linear-gradient(270deg, #E8488A 0%, #D5304B 100%);
    color: #fff;
    text-decoration: none;
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    line-height: 16px;
    text-align: center;
  }